import React, { useState, useRef } from "react";

import game_logo from "../img/game_logo.png";
import logo from "../img/logo.png";
import c1 from "../img/c1.png";
import c2 from "../img/c2.png";
import c3 from "../img/c3.png";
import c4 from "../img/c4.png";

function Q1(props) {
  let a = [
    'Jamais ou très rarement',
    'De temps en temps',
    '1 par jour',
    '2 ou 3 par jour',
    'Plus de 3 par jour',    
  ];
  return (
    <div className="q">
      <h2>A quelle fréquence buvez-vous du café ?</h2>
      <div className="radios">
        
      {a.map((el,idx)=>(
        <div className="radio" key={el}>
          <input
            type="radio"
            id={"radio"+(idx+1)}
            name="radio"
            value={el}
            onChange={props.onChange}
          />
          <label htmlFor={"radio"+(idx+1)}>
            <div className="checker"></div>
            {el}
          </label>
        </div>
        ))}

      </div>
    </div>
  );
}

function Q2(props) {  
  let a = [
    'Bien vous réveiller',
    'Passer un bon moment',
    'Déstresser',    
  ];
  return (
    <div className="q">
      <h2>Vous buvez un café pour:</h2>
      <div className="radios">
        {a.map((el,idx)=>(
        <div className="radio" key={el}>
          <input
            type="radio"
            id={"radio"+(idx+1)}
            name="radio"
            value={el}
            onChange={props.onChange}
          />
          <label htmlFor={"radio"+(idx+1)}>
            <div className="checker"></div>
            {el}
          </label>
        </div>
        ))}
        

        
      </div>
    </div>
  );
}


function Q3(props) {
  let a = [
    'Fruité + Miel',
    'Fruité + Noisettes & Chocolat',
    'Fruits secs + Noisettes & Chocolat',    
    'Fruité + Épicé',
  ];
  return (
    <div className="q">
      <h2>Quel goût/ Arômes préférez-vous dans votre café ?</h2>
      <div className="radios">
        
      {a.map((el,idx)=>(
        <div className="radio" key={el}>
          <input
            type="radio"
            id={"radio"+(idx+1)}
            name="radio"
            value={el}
            onChange={props.onChange}
          />
          <label htmlFor={"radio"+(idx+1)}>
            <div className="checker"></div>
            {el}
          </label>
        </div>
        ))}

      </div>
    </div>
  );
}

function Q4(props) {
  // debugger;
  console.log(props)  ;
  let a=null;
  if (props.data.q3=='Fruité + Noisettes & Chocolat')
  a = [    
    'Acidulé',
    'Légèrement Acidulé',    
  ];
  if (props.data.q3=='Fruits secs + Noisettes & Chocolat')
  a = [
    'Épicé',
    'Non épicé',
  ];
  if (!a) return null;
  return (
    <div className="q">
      <h2>Comment le préférez-vous ?</h2>
      <div className="radios">
        
      {a.map((el,idx)=>(
        <div className="radio" key={el}>
          <input
            type="radio"
            id={"radio"+(idx+1)}
            name="radio"
            value={el}
            onChange={props.onChange}
          />
          <label htmlFor={"radio"+(idx+1)}>
            <div className="checker"></div>
            {el}
          </label>
        </div>
        ))}

      </div>
    </div>
  );
}


function CMP(props) {
  const [q, set_q] = useState(1);
  const [data, set_data] = useState({});
  const [loading, set_loading] = useState(false);

  let q4 = Q4({ data:data, onChange:(e) => save("q4", e.target.value)});
  console.log({q4});

  let save = (q, v) => {
    set_loading(true);
    let fd = new FormData();
    fd.set(q, v);
    fd.set("id", props.data.user.id);
    fd.set("cs", props.data.user.cs);
    let nd = { ...data, [q]: v };
    if (q=='q3') delete nd['q4'];
    set_data(nd);
console.log({ ...data, [q]: v });
    fetch(props.data.url, {
      method: "POST",
      body: fd,
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        //nextQ({ [q]: v });        
      })
      .catch((err) => {
        console.log(err);
        alert("Vérifier votre connexion!");
      })
      .finally(() => set_loading(false));
  };
  let nextQ = (r = null) => {    
    if (!data['q'+q]) return;
    if (q <= 2 || q==3 && q4) set_q(q + 1);
    else props.onDone(data);
  };
  let prevQ = () => {
    if (q != 1) set_q(q - 1);
  };

  return (
    <div className="page-questions container-fluid">
      <img src={game_logo} className="game_logo_min" />
      <img src={logo} className="logo" />

      {q == 1 && <Q1 onChange={(e) => save("q1", e.target.value)} />}
      {q == 2 && <Q2 onChange={(e) => save("q2", e.target.value)} />}
      {q == 3 && <Q3 data={data} onChange={(e) => save("q3", e.target.value)} />}
      {q == 4 && q4}

      <div className="qb">
        {(q != 1) && <button className="btn btn-default" onClick={prevQ}>
          Question Précédente
        </button>
        }
        <button className="btn btn-default" onClick={nextQ}>
          {(q <= 2 || (q==3 && q4)) ? 'Question Suivante' : 'Voir le résultat'}
        </button>
      </div>
    </div>
  );
}

export default CMP;
