import React, { useState, useRef } from "react";

import "./App.css";

import Home from "./cmp/Home";
import Inscription from "./cmp/Inscription";
import Questions from "./cmp/Questions";
import Result from "./cmp/Result";

import game_logo from "./img/game_logo.png";
import logo from "./img/logo.png";
import bg from "./img/bg.jpg";

function App() {
  let toResult = false;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  params.r = parseInt(params.r);
  if (params.r && params.r>=1 && params.r<=7) toResult = true;

  const [data, set_data] = useState({
    url: window.location.hostname=='localhost' ? "http://localhost/y/app/public/script2.php" : './script2.php',
    user: {},
    page: toResult ? "result" : "home",
  });

  let p = (
    <Home
      data={data}
      onNext={() => set_data({ ...data, page: "inscription", __page:"questions" })}
    />
  );
  if (data.page == "inscription")
    p = (
      <Inscription
        data={data}
        onNext={(r) => {
          set_data({ ...data, page: "questions", user: r });
        }}
      />
    );
  if (data.page == "questions")
    p = (
      <Questions   
        data={data}     
        onChange={(r) => set_data({ ...data, user: { ...data.user, ...r } })}
        onDone={(r) =>
          set_data({ ...data, user: { ...data.user, ...r }, page: "result" })
        }
      />
    );
  if (data.page == "result") p = <Result data={data} goBack={() =>
    set_data({ ...data, page: "questions" })
  } />;
  return (
    <>
      <img src={bg} className="bg" />
      <div className="bgc" />
      
      {p}
    </>
  );
}

export default App;
