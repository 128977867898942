import React, { useState, useRef } from "react";
import game_logo from "../img/game_logo.png";
import logo from "../img/logo.png";

function CMP(props) {
  const [loading, set_loading] = useState(false);
  const [errors, set_errors] = useState({});
  const form = useRef();
  let submit = (e) => {
    e.preventDefault();
    set_loading(true);
    fetch(props.data.url, {
      method: "POST",
      body: new FormData(form.current),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response.id) props.onNext(response);
        else if (response.error) set_errors(response.error);
      })
      .catch((err) => {
        console.log(err);
        alert("Vérifier votre connexion!");
      })
      .finally(() => set_loading(false));
  };

  return (
    <div className="page-inscri container-fluid">
      <form onSubmit={submit} ref={form}>
        <img src={game_logo} className="game_logo_min" />
        <img src={logo} className="logo" />
        
        <div className="row">          
          <div className="col-12 text-center">
            <h2>Inscrivez-vous ici pour participer</h2>
          </div>
          <div className="col-6 text-end">
            <input type="text" name="fname" placeholder="Prénom" />            
            {errors && errors['fname'] && <span className="badge bg-danger">{errors['fname']}</span>}
          </div>
          <div className="col-6 text-start">
            <input type="text" name="lname" placeholder="Nom" />
            {errors && errors['lname'] && <span className="badge bg-danger">{errors['lname']}</span>}
          </div>
          <div className="col-12 text-center">
            <input type="text" name="email" placeholder="Email" />
            {errors && errors['email'] && <span className="badge bg-danger">{errors['email']}</span>}
          </div>
          <div className="col-12 text-center">
            <input type="text" name="tel" placeholder="N° de téléphone" />
            {errors && errors['tel'] && <span className="badge bg-danger">{errors['tel']}</span>}
          </div>
          <div className="col-12 text-center">
            <button className="btn btn-primary" type="submit">
              Participer
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CMP;
