import game_logo from "../img/game_logo.png";
import logo from "../img/logo.png";
import j1 from "../img/j1.png";
import j2 from "../img/j2.png";
import j3 from "../img/j3.png";
import r0 from "../img/thx.png";
import r1 from "../img/tanzanie.png";
import r2 from "../img/ethopie.png";
import r3 from "../img/bresil.png";
import r4 from "../img/nicaragua.png";
import r5 from "../img/kenya.png";
import r6 from "../img/colombia.png";
import r7 from "../img/honduras.png";
import { Helmet } from "react-helmet";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";

let resultsData = [];
resultsData.push({
  img: r0,
  s1: "Notre CAFÉ 100% ARABICA",
  s2: "Merci !",
  s3: "Nous vous remercions pour votre participation.",
});
resultsData.push({
  img: r1,
  s1: "Notre CAFÉ 100% ARABICA",
  s2: "ORIGINE Tanzanie",
  s3: "avec des légéres notes de miel et de citron, est le meilleur pour vous.",
});
resultsData.push({
  img: r2,
  s1: "Notre CAFÉ 100% ARABICA",
  s2: "ORIGINE ETHIOPIE",
  s3: "le plus exceptionnel des cafés au savoureux mélange de notes de fruits à noyaux et de pignons de pin, est le meilleur pour vous.",
});
resultsData.push({
  img: r3,
  s1: "Notre CAFÉ 100% ARABICA",
  s2: "ORIGINE Brésil",
  s3: "au goût sucré avec légères notes de noisettes et de chocolat, est le meilleur pour vous.",
});
resultsData.push({
  img: r4,
  s1: "Notre CAFÉ 100% ARABICA",
  s2: "ORIGINE NICARAGUA",
  s3: "remarquable par sa douceur et sa saveur d’agrumes, est le meilleur pour vous.",
});
resultsData.push({
  img: r5,
  s1: "Notre CAFÉ 100% ARABICA",
  s2: "ORIGINE KENYA",
  s3: "aux notes fruitées légèrement épicées, équilibré, est le meilleur pour vous.",
});
resultsData.push({
  img: r6,
  s1: "Notre CAFÉ 100% ARABICA",
  s2: "ORIGINE COLOMBIE",
  s3: "Puissant en bouche aux arômes cacaotés, légèrement caramélisés, est le meilleur pour vous.",
});
resultsData.push({
  img: r7,
  s1: "Notre CAFÉ 100% ARABICA",
  s2: "ORIGINE HONDURAS",
  s3: "à la saveur chocolatée accompagnée d’une agréable note fruitée, est le meilleur pour vous.",
});

function CMP(props) {
  let idx = 0;
  let qData = [props.data.user.q1, props.data.user.q2, props.data.user.q3, props.data.user.q4];
  console.log(props.data, qData);
  let y = 0;
  let js = [
    'Vous êtes amateur de café',
    'Vous êtes passionné de café',
    'Vous êtes carrément accro au café',
  ];
  let jsImg = [j1, j2, j3];
  if (qData[0] == '1 par jour') y = 1;
  if (qData[0] == '2 ou 3 par jour') y = 2;
  if (qData[0] == 'Plus de 3 par jour') y = 3;

  if (qData[2] == "Fruité + Miel") idx = 1;
  else if (qData[2] == "Fruité + Noisettes & Chocolat") {
    idx = qData[3] == "Acidulé" ? 5 : 6;
  }
  else if (qData[2] == "Fruits secs + Noisettes & Chocolat") {
    idx = qData[3] == "Épicé" ? 7 : 4;
  }
  else if (qData[2] == "Fruité + Épicé") idx = 2;


  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  params.r = parseInt(params.r);
  if (params.r && params.r >= 1 && params.r <= 7) idx = params.r;

  let item = resultsData[idx];

  item.s1 = item.s1 + ' ' + item.s2.replace('ORIGINE ','');
  item.s2 = '';

  let shareUrl = 'https://' + window.location.host + '/?r=' + idx;
  let imgUrl = 'https://' + window.location.host + item.img;
  let title = 'Grain de Coeur - ' + item.s2;
  return (
    <div className="page-result container-fluid">
      <img src={game_logo} className="game_logo_min" onClick={props.goBack} />
      <img src={logo} className="logo" />

      <Helmet>
        <meta property="image" content={imgUrl} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        {/* <meta property="og:quote" content={quote} />         */}
        <meta property="og:image" content={imgUrl} />
        <meta content="image/*" property="og:image:type" />
        <meta property="og:url" content={shareUrl} />
        <meta property="og:site_name" content={title} />
        <meta property="og:description" content={item.s1} />    
      </Helmet>

      <div className="row">
        <div className="col-6 d-flex justify-content-center align-items-center">
          <img src={item.img} className="res-img" />
        </div>
        <div className="col-6 d-flex flex-column justify-content-center align-items-center">
          <p>{y ? js[y-1] : ''} {!y ? null : <img src={jsImg[y-1]} style={{height:30}} />}</p>
          <h3>{item.s1}</h3>
          {/* <h1>{item.s2}</h1> */}
          <p>{'est celui qui vous correspond le plus'}</p>

          <div className="sharing">
            <FacebookShareButton
              url={shareUrl}
              quote={title}
              className="share-button"
            >
              <FacebookIcon size={50} round />
            </FacebookShareButton>

            <TwitterShareButton
              url={shareUrl}
              title={title}
              className="share-button"
            >
              <TwitterIcon size={50} round />
            </TwitterShareButton>

            <WhatsappShareButton
              url={shareUrl}
              title={title}
              separator=":: "
              className="share-button"
            >
              <WhatsappIcon size={50} round />
            </WhatsappShareButton>

            <EmailShareButton
              url={shareUrl}
              subject={title}
              body="body"
              className="share-button"
            >
              <EmailIcon size={50} round />
            </EmailShareButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CMP;
