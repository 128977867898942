import game_logo from '../img/game_logo.png';
import logo from '../img/logo.png';
import map from '../img/map.png';

function CMP(props) {
    return (        
        <>
        <img src={logo} class="logo" />
        <div class="container-fluid">            
            <div class="row page page-home">
            <div class="col-6 d-flex flex-column justify-content-center align-content-center">
                <div class="intro">Quelle origine de café vous ressemble le plus?</div>
                <div><img src={game_logo} class="game_logo" /></div>                
                <div>
                    <button class="btn btn-primary" onClick={props.onNext} style={{fontWeight:'bold'}} >
                        Lancer le test
                    </button>
                </div>
            </div>
            <div class="col-6  d-flex flex-column justify-content-center align-content-end">
                <img src={map} class="map"/>
            
            </div>
            </div>
        </div>
        </>
    );
}

export default CMP;